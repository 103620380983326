

















import {Component, Vue} from 'vue-property-decorator';
import ChuFangApi from '@/api/chufang'

@Component({})
export default class UserIndex extends Vue {
  private api = new ChuFangApi()

  private async handleLogout() {
    Vue.prototype.$Cookies.remove('Keep_Token', {path: '/'})
    localStorage.removeItem('Keep_OpenId')
    localStorage.removeItem('Keep_NickName')
    localStorage.removeItem('Keep_FaceUrl')
    localStorage.removeItem('Keep_ShipType')
    localStorage.removeItem('Keep_RealName')

    this.$toast('退出成功')
    location.reload()
  }

  private async handleChufang() {
    const result = await this.api.getLastAssessment(true)
    if (result) {
      await this.$router.replace('/personal-tailor/body-analysis')
    } else {
      await this.$router.replace('/personal-tailor/chu-fang')
    }
  }
};
